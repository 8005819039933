import { useContext, useEffect, useState } from 'react';
import { TbShoppingBagPlus } from 'react-icons/tb';
import { TiPlus } from 'react-icons/ti';
import { TiMinus } from 'react-icons/ti';

import appContext from '../context/appContext';
import style from './ItemAmountButtons.module.css';
import Button from './Button';

function ItemAmountButtons({ id }) {
    const { cart, setCart, products } = useContext(appContext);
    const [amount, setAmount] = useState(0);
    useEffect(() => {
        const item = cart.find((el) => el.id === id);
        if (!!item) setAmount(item.amount);
    }, [id, cart]);

    const product = Object.fromEntries(
        products.map((item) => [
            item.id,
            {
                price: item.discount === 0 ? item.price : item.promoPrice,
                imgUrl: item.imgUrl,
                title: item.title,
                discountForbidden: item.discountForbidden,
                noDiscountPrice: item.price,
                discount: item.discount,
            },
        ])
    );
    const changeAmount = (id, action = '+') => {
        const newAmount = amount + (action === '+' ? 1 : amount && -1);
        setAmount(newAmount);
        const sum = Math.round(product[id].price * newAmount * 100) / 100;

        setCart(() => {
            return cart.find((el) => el.id === id)
                ? cart.map((el) => (el.id !== id ? { ...el } : { ...el, sum, amount: newAmount }))
                : [
                      {
                          id,
                          amount: 1,
                          price: product[id].price,
                          sum,
                          imgUrl: product[id].imgUrl,
                          title: product[id].title,
                          related: false,
                          discountForbidden: product[id].discountForbidden,
                          noDiscountPrice: product[id].noDiscountPrice,
                          discount: product[id].discount,
                      },
                      ...cart,
                  ];
        });
    };

    return (
        <div className={style.itemButtons}>
            {!amount ? (
                <Button btnclass="itemButtonBuy" onClick={() => changeAmount(id)}>
                    <TbShoppingBagPlus className={style.iconBag} />
                    Беру!
                </Button>
            ) : (
                <>
                    <Button btnclass="itemMinus" onClick={() => changeAmount(id, '-')}>
                        <TiMinus />
                    </Button>
                    <span className={style.itemAmоunt}>{amount}</span>
                    <Button btnclass="itemPlus" onClick={() => changeAmount(id)}>
                        <TiPlus />
                    </Button>
                </>
            )}
        </div>
    );
}

export default ItemAmountButtons;
