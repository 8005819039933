import { useContext, useState, useEffect, forwardRef } from 'react';
import appContext from '../../context/appContext';
import SearchResults from './SearchResults';
import style from './Search.module.css';

const Search = forwardRef(({ onClose }, ref) => {
    const { products } = useContext(appContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);

    const searchResultsCount = 25;
    useEffect(() => {
        const handleSearch = () => {
            if (searchTerm.length >= 3) {
                const filteredResults = products
                    .filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase()))
                    .slice(0, searchResultsCount);
                setResults(filteredResults);
            } else {
                setResults([]);
            }
        };

        const timeoutId = setTimeout(handleSearch, 300);
        return () => clearTimeout(timeoutId);
    }, [searchTerm, products]);

    const handleClose = () => {
        setSearchTerm('');
        setResults([]);
        onClose(); // Закрываем сам компонент Search
    };

    return (
        <div className={style.searchContainer}>
            <input
                type="text"
                placeholder="Пошук по назві"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={style.searchInput}
                ref={ref}
            />
            <button className={style.closeButton} onClick={handleClose}>
                ✖
            </button>
            {results.length > 0 && <SearchResults results={results} />}
        </div>
    );
});

export default Search;
