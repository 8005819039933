import { useMemo } from 'react';
import style from './Favorite.module.css';
import Product from '../product/Product';

function Favorite({ products }) {
    const favorites = useMemo(() => {
        return products ? products.filter((item) => item.isFavorite) : [];
    }, [products]);

    if (favorites.length === 0) {
        return <h3>У вас немає улюблених страв.</h3>;
    }

    return (
        <div className={style.itemsList}>
            {favorites.map((item) => (
                <Product item={item} key={item.id} />
            ))}
        </div>
    );
}

export default Favorite;
