import React, { useEffect, useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import 'react-datepicker/dist/react-datepicker.css';
import style from './DateTimePicker.module.css';

registerLocale('uk', uk);
setDefaultLocale('uk');

const DateTimePicker = React.memo(({ formData, handleFormChange }) => {
    const [curDate, setCurDate] = useState(new Date(formData.deliveryDate || Date.now()));
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setCurDate(new Date(formData.deliveryDate));
    }, [formData.deliveryDate]);

    const handleDateChange = (date) => {
        setCurDate(date);
        handleFormChange({ target: { name: 'deliveryDate', value: date.toISOString().split('T')[0] } });
        setIsOpen(false);
    };

    const generateTimeSlots = useMemo(() => generateDeliveryTimeSlots(curDate, formData.delivery), [curDate, formData.delivery]);

    const formatDateDisplay = (date) => {
        const today = new Date();
        const tomorrow = new Date(Date.now() + 86400000);

        if (date.toDateString() === today.toDateString()) return 'Сьогодні';
        if (date.toDateString() === tomorrow.toDateString()) return 'Завтра';
        return date.toLocaleDateString('uk-UA');
    };

    return (
        <div className={style.container}>
            <div className={style.dateContainer}>
                <label htmlFor="displayDate">Дата:</label>
                <div className={style.displayDateWrapper}>
                    <input
                        type="text"
                        id="displayDate"
                        value={formatDateDisplay(curDate)}
                        readOnly
                        onClick={() => setIsOpen((prev) => !prev)}
                        className={style.deliveryDate}
                    />
                    {isOpen && (
                        <DatePicker
                            selected={curDate}
                            onChange={handleDateChange}
                            inline
                            minDate={new Date()}
                            locale="uk"
                            onClickOutside={() => setIsOpen(false)}
                        />
                    )}
                </div>
            </div>
            <DeliveryTiming
                deliveryTiming={formData.deliveryTiming}
                setDeliveryTiming={(value) => {
                    handleFormChange({ target: { name: 'deliveryTiming', value } });
                }}
                currentNearestTime={getAdjustedTime(formData.delivery)}
                curDate={curDate}
            />
            {formData.deliveryTiming === 'timed' && <TimeSlotSelector generateTimeSlots={generateTimeSlots} handleFormChange={handleFormChange} />}
        </div>
    );
});

const generateDeliveryTimeSlots = (curDate, delivery) => {
    const slots = [];
    const now = new Date();
    const startHour = 11;
    const startMinutes = delivery ? 30 : 0;
    const endHour = delivery ? 22 : 21;

    for (let hour = startHour; hour <= endHour; hour++) {
        const initialMinutes = hour === startHour ? startMinutes : 0;
        const maxMinutes = hour === 21 && !delivery ? 30 : 60; // 21:30 для без самовывоз

        for (let minutes = initialMinutes; minutes < maxMinutes; minutes += 30) {
            const startTime = new Date(curDate);
            startTime.setHours(hour, minutes, 0, 0);

            if (curDate.toDateString() === now.toDateString() && startTime < now) continue;

            const endTime = new Date(startTime);
            endTime.setMinutes(endTime.getMinutes() + 30);
            slots.push(`${startTime.toTimeString().slice(0, 5)}-${endTime.toTimeString().slice(0, 5)}`);
        }
    }
    return slots;
};

const DeliveryTiming = ({ deliveryTiming, setDeliveryTiming, currentNearestTime, curDate }) => {
    const now = new Date();
    const currentHours = now.getHours();
    const isNightTime = currentHours >= 21 || currentHours < 10; // Проверяем, в диапазоне ли время

    return (
        <div className={style.deliveryOptionContainer}>
            {curDate.toDateString() === new Date().toDateString() && !isNightTime && (
                <div className={style.switchOption}>
                    <input
                        type="radio"
                        id="nearest"
                        name="deliveryTiming"
                        value="nearest"
                        checked={deliveryTiming === 'nearest'}
                        onChange={() => setDeliveryTiming('nearest')}
                        className={style.toggleOption}
                    />
                    <label htmlFor="nearest" className={style.toggleOptionLabel}>
                        На найближчий час {currentNearestTime}
                    </label>
                </div>
            )}
            <div className={style.switchOption}>
                <input
                    type="radio"
                    id="timed"
                    name="deliveryTiming"
                    value="timed"
                    checked={deliveryTiming === 'timed'}
                    onChange={() => setDeliveryTiming('timed')}
                    className={style.toggleOption}
                />
                <label htmlFor="timed" className={style.toggleOptionLabel}>
                    На час
                </label>
            </div>
        </div>
    );
};

const TimeSlotSelector = ({ generateTimeSlots, handleFormChange }) => (
    <div className={style.timeContainer}>
        <select name="timeInterval" className={style.timeSelect} onChange={handleFormChange}>
            <option value="" disabled>
                Оберіть час
            </option>
            {generateTimeSlots.map((time) => (
                <option key={time} value={time}>
                    {time}
                </option>
            ))}
        </select>
    </div>
);

const getAdjustedTime = (delivery) => {
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();

    if (!delivery && currentHours === 10 && currentMinutes >= 0 && currentMinutes <= 30) {
        now.setMinutes(now.getMinutes() + 75); // Добавляем 75 минут
    } else if (delivery && currentHours >= 10 && currentHours < 21) {
        now.setMinutes(now.getMinutes() + 45); // Добавляем 45 минут
    } else {
        now.setMinutes(now.getMinutes() + 45); // Стандартное добавление 30 минут
    }

    const minutes = now.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 5) * 5;

    now.setMinutes(roundedMinutes);
    if (roundedMinutes === 60) {
        now.setHours(now.getHours() + 1);
        now.setMinutes(0);
    }

    return '... до ' + now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export default DateTimePicker;
