import { useContext } from 'react';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import style from './Photo.module.css';
import AppContext from '../../context/appContext';

function Photo({ item, changeState, modal }) {
    const { setIsFavorite, identified } = useContext(AppContext);

    const handleFavoriteClick = () => {
        setIsFavorite(item.id);
    };

    // const calcPersent = () => {
    //     const discount = ((item.price - item.promoPrice) / item.price) * 100;
    //     return `-${Math.round(discount)}%`;
    // };

    const imageWrapperClass = modal ? style.imageWrapperModal : style.imageWrapper;
    const imageClass = modal ? style.itemImageModal : style.itemImage;

    return (
        <div className={imageWrapperClass}>
            {item.promoPrice !== 0 && (
                <div className={modal ? style.productLabelModal : style.productLabel}>
                    <span className={modal ? style.discountTextModal : style.discountText}>{`-${item.discount}%`}</span>
                </div>
            )}
            <img src={item.imgUrl} alt={item.description || 'Product Image'} className={imageClass} onClick={changeState} />
            {identified && (
                <button className={style.buttonFavoriteList} onClick={handleFavoriteClick}>
                    {item.isFavorite ? <FaHeart /> : <FaRegHeart />}
                </button>
            )}
        </div>
    );
}

export default Photo;
