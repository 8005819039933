import { useContext, useEffect, useState } from 'react';
import Modal from '../../modal/Modal';
import style from './ProductModal.module.css';
import Photo from '../photo/Photo';
import ItemCost from '../../UI/ItemCost';
import ItemAmountButtons from '../../UI/ItemAmountButtons';
import AppContext from '../../context/appContext';

function ProductModal({ item, changeState }) {
    const { cart } = useContext(AppContext);
    const { title, price, promoPrice, discount, composition, description } = item;
    const [amount, setAmount] = useState(0);

    const curPrice = discount === 0 ? price : promoPrice;

    useEffect(() => {
        const cartEl = cart.find((el) => el.id === item.id);
        setAmount(cartEl ? cartEl.amount : 0);
    }, [cart, item]);

    return (
        <Modal showModalWindow={changeState}>
            <div className={style.closeModal} onClick={changeState}></div>
            <h3 className={style.title}>{title}</h3>
            <Photo item={item} changeState={changeState} modal={true} />
            <div className={style.textWrapper}>
                <ItemCost cost={price} sum={curPrice * amount} />
                <ItemAmountButtons id={item.id} />
                <div className={style.description}>
                    <p>{composition}</p>
                    <p>{description}</p>
                </div>
            </div>
        </Modal>
    );
}

export default ProductModal;
