import { useContext, useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { RiSearchLine } from 'react-icons/ri';
import { TbShoppingBag } from 'react-icons/tb';
import CategoryMenu from '../categoryMenu/CategoryMenu';
import BurgerMenu from '../burgerMenu/BurgerMenu';
import Button from '../../UI/Button';
import appContext from '../../context/appContext';
import Search from '../search/Search';
import style from './Header.module.css';

function Header() {
    const [bMenu, setBMenu] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);
    const { cart } = useContext(appContext);
    const searchInputRef = useRef(null);

    const toggleBMenu = () => setBMenu((prev) => !prev);
    const toggleSearch = () => {
        setSearchVisible((prev) => !prev);
    };

    const closeSearch = () => {
        setSearchVisible(false);
    };

    useEffect(() => {
        if (searchVisible) {
            document.body.style.overflow = 'hidden';
            searchInputRef.current?.focus();

            return () => {
                document.body.style.overflow = 'auto';
            };
        }
    }, [searchVisible]);

    const itemCount = cart.reduce((acc, cur) => acc + cur.amount, 0);

    return (
        <div className={style.header}>
            <BurgerMenu showBMenu={toggleBMenu} active={bMenu} />
            <div className={style.headerLogo}>
                <Button btnclass="headerButton" onClick={toggleBMenu}>
                    <GiHamburgerMenu className={style.buttonsIcon} />
                </Button>
                <NavLink to="/" className={style.logoLink}>
                    <div className={style.logo}></div>
                </NavLink>
                <Button btnclass="headerButton" onClick={toggleSearch}>
                    <RiSearchLine className={style.buttonsIcon} />
                </Button>
                {searchVisible && <Search ref={searchInputRef} onClose={closeSearch} />}
                <NavLink to="cart" className={style.cartLink}>
                    <div>
                        <TbShoppingBag className={style.buttonsIcon} />
                        {itemCount > 0 && <div className={style.dishCount}>{itemCount}</div>}
                    </div>
                </NavLink>
            </div>
            <CategoryMenu />
        </div>
    );
}

export default Header;
