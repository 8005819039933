import { useContext, useEffect, useState } from 'react';
import ItemAmountButtons from '../../UI/ItemAmountButtons';
import ItemCost from '../../UI/ItemCost';
import style from './Product.module.css';
import ProductModal from './ProductModal';
import Photo from '../photo/Photo';
import AppContext from '../../context/appContext';

function Product({ item }) {
    const { cart } = useContext(AppContext);
    const { title, price, promoPrice, discount, description } = item;

    const [modalOpen, setModalOpen] = useState(false);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        const cartItem = cart.find((el) => el.id === item.id);
        if (cartItem) {
            setAmount(cartItem.amount);
        }
    }, [cart, item.id]);

    const toggleModal = () => {
        document.body.style.overflow = !modalOpen ? 'hidden' : '';
        setModalOpen((prev) => !prev);
    };

    const curPrice = discount === 0 ? price : promoPrice;

    return (
        <>
            {modalOpen && <ProductModal item={item} changeState={toggleModal} state={modalOpen} />}
            <div className={style.menuItem}>
                <div className={style.itemContent}>
                    <h3 className={style.itemTitle}>{title}</h3>
                    <ItemCost cost={price} bold={discount === 0} />
                    {discount !== 0 ? (
                        <div style={{ textAlign: 'left' }}>
                            <span>{`Знижка: ${discount}%`}</span>
                            <ItemCost cost={promoPrice} description="зі знижкою" bold={true} />
                        </div>
                    ) : (
                        <p className={style.itemDescription}>{description}</p>
                    )}
                    {amount > 0 && <ItemCost description="Сума" cost={curPrice * amount} bold={true} />}
                </div>
                <div className={style.itemAppearance}>
                    <Photo item={item} changeState={toggleModal} modal={false} />
                    <ItemAmountButtons id={item.id} />
                </div>
            </div>
        </>
    );
}

export default Product;
