import CartProduct from './CartProduct';
import CartRelatedProduct from './CartRelatedProduct';

import style from './CartProducts.module.css';

function CartProducts({ cart, setCart, handlerSum }) {
    const updateAmount = (id, newAmount, newSum) => {
        const newCart = cart.map((item) => (item.id === id ? { ...item, amount: newAmount, sum: newSum } : item));
        setCart(newCart);
        handlerSum(newCart.reduce((sum, item) => sum + item.sum, 0));
    };

    const removeProduct = (id) => {
        const newCart = cart.filter((item) => item.id !== id);
        setCart(newCart);
        handlerSum(newCart.reduce((sum, item) => sum + item.sum, 0));
    };

    const products = cart.filter((cartItem) => !cartItem.related);
    const related = cart.filter((cartItem) => cartItem.related);
    return (
        <>
            <div className={style.conteiner}>
                {products.map((cartItem) => {
                    return <CartProduct key={cartItem.id} cartItem={cartItem} updateAmount={updateAmount} removeProduct={removeProduct} />;
                })}
                {related.map((cartItem, index) => {
                    return <CartRelatedProduct key={cartItem.id} cartItem={cartItem} updateAmount={updateAmount} index={index} />;
                })}
            </div>
        </>
    );
}

export default CartProducts;
