import { useCallback, useEffect, useState, useMemo } from 'react';
import style from './MyOrders.module.css';
import { fetchData } from '../../data/fetchData';

function MyOrders({ myOrdersUrl, initData }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [orders, setOrders] = useState([]);

    const getMyOrders = useCallback(async () => {
        setLoading(true);
        setError('');
        try {
            const responseData = await fetchData(myOrdersUrl, 'POST', { initData });
            if (responseData.success) {
                setOrders(responseData.orders);
            } else {
                setError(responseData.error || 'Помилка при отриманні замовлень. Спробуйте ще раз.');
            }
        } catch (error) {
            setError('Сталася помилка при отриманні замовлень. Будь ласка, спробуйте ще раз.');
        } finally {
            setLoading(false);
        }
    }, [initData, myOrdersUrl]);

    useEffect(() => {
        getMyOrders();
    }, [getMyOrders]);

    const ordersContent = useMemo(() => {
        if (loading) {
            return <p className={style.loading}>Завантаження...</p>;
        }

        if (error) {
            return <p className={style.error}>{error}</p>;
        }

        if (orders.length === 0) {
            return <p className={style.noOrders}>У вас немає замовлень.</p>;
        }

        return (
            <ul className={style.ordersList}>
                {orders.map((order) => (
                    <li key={order.id} className={style.orderItem}>
                        <p>Замовлення №{order.id}</p>
                        <p>Дата: {order.date}</p>
                    </li>
                ))}
            </ul>
        );
    }, [loading, error, orders]);

    return <div className={style.container}>{ordersContent}</div>;
}

export default MyOrders;
