import React, { useState } from 'react';
import style from './Payment.module.css';

const paymentOptions = [
    { id: 'cash', desc: 'Готівка' },
    { id: 'account', desc: 'Рахунок' },
    { id: 'card', desc: 'Карта' },
];

function Payment({ dataChange }) {
    const [selectedOption, setSelectedOption] = useState(paymentOptions[0].id);

    const handleChange = (event) => {
        const { value } = event.target;
        setSelectedOption(value);
        dataChange(event);
    };

    return (
        <div className={style.container}>
            <h3 className={style.title}>Оберіть спосіб оплати</h3>
            <div className={style.paymentOptions}>
                {paymentOptions.map(({ id, desc }) => (
                    <div className={style.paymentOption} key={id}>
                        <input
                            type="radio"
                            id={id}
                            name="payment"
                            value={id}
                            checked={selectedOption === id}
                            onChange={handleChange}
                            className={style.radioInput}
                        />
                        <label htmlFor={id} className={style.radioLabel}>
                            {desc}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Payment;

// import React, { useState } from 'react';
// import style from './Payment.module.css';

// function Payment({ dataChange }) {
//     const [selectedOption, setOption] = useState('card');

//     const handleChange = (event) => {
//         setOption(event.target.value);
//         dataChange(event);
//     };

//     return (
//         <div className={style.container}>
//             <h3 className={style.title}>Оберіть спосіб оплати</h3>
//             <div className={style.paymentOptions}>
//                 {[
//                     { id: 'cash', desc: 'Готівка' },
//                     { id: 'account', desc: 'Рахунок' },
//                     { id: 'card', desc: 'Карта' },
//                 ].map((option) => (
//                     <div className={style.paymentOption} key={option.id}>
//                         <input
//                             type="radio"
//                             id={option.id}
//                             name="payment"
//                             value={option.id}
//                             checked={selectedOption === option.id}
//                             onChange={handleChange}
//                             className={style.radioInput}
//                         />
//                         <label htmlFor={option.id} className={`${style.radioLabel} ${selectedOption === option.id ? 'selected' : ''}`}>
//                             {option.desc}
//                         </label>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }

// export default Payment;
