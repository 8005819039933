import styles from './Delivery.module.css';

function Delivery() {
    const locations = [
        'Коцюбинське',
        'Чайки',
        'Петропавлівська Борщагівка',
        'Софіївська Борщагівка',
        'Святопетрівське',
        'Вишневе',
        'Гатне',
        'Крюківщина',
        'Чабани',
        'Віта-Поштова',
        'Погреби',
        'Новосілки',
        'Хотів',
        'Лісники',
        'Ходосівка',
        'Підгірці',
        'Романків',
        'Конча-Заспа',
        'Козин',
    ];

    return (
        <div className={styles.informationView}>
            <h2>Умови доставки та оплати</h2>
            <h3>
                <strong>ДОСТАВКА БЕЗКОШТОВНА!</strong>
            </h3>
            <h3>В ЯКІ РАЙОНИ МИ ЗДІЙСНЮЄМО ДОСТАВКУ?</h3>
            <p>Доставка у нас здійснюється по всьому Києву та в найближче передмістя:</p>
            {locations.sort().map((location) => (
                <strong className={styles.location} key={location}>
                    {location}
                </strong>
            ))}
            <p>
                Доставка від 30 хвилин до 2-х годин 20 хвилин в залежності від адреси доставки, завантаженості на виробництві та ситуації на дорогах!
            </p>
            <p>
                Сума мінімального замовлення складає від 300.00 грн до 500.00 грн. з урахуванням усіх знижок, в залежності від адреси, доставка
                безкоштовна.
            </p>
            <p>
                <strong>Також у нас є САМОВИВІЗ за адресами:</strong>
            </p>
            <p>
                <strong>м.Київ, вул. Деміївська 13 (ЖК Soho Residense)</strong>
            </p>
            <p>
                <strong>м. Київ, вул. Воздвиженська 20 (Поділ)</strong>
            </p>
            <p>* На самовивіз діє знижка 15% або 20% для постійних клієнтів</p>
            <p>* Мінімальна сума замовлення складає 300.00 грн.</p>
            <p>
                * Час приготування замовлення на Самовивіз <strong>від 5 хвилин!</strong>
            </p>
            <h3>Способи оплати:</h3>
            <ul>
                <li>Картка (термінал буде у кур'єра);</li>
                <li>Оплата на розрахунковий рахунок;</li>
                <li>Готівка;</li>
            </ul>
            <p>Якщо у Вас залишилися питання, ви завжди можете зателефонувати нашим менеджерам, і вони вас з радістю проконсультують!</p>
            <p>З любов'ю команда "ЇМ РИБУ І ХУДНУ"!</p>
        </div>
    );
}

export default Delivery;
