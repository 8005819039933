import style from './Home.module.css';
import Contacts from '../info/Contacts';

function Home() {
    return (
        <div className={style.content}>
            <h3>Вас вітає Краща доставка суші в Києві - «ЇМ РИБУ І ХУДНУ»!</h3>
            <Contacts />
        </div>
    );
}

export default Home;
