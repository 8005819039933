import styles from './Privacy.module.css';
function Privacy() {
    return (
        <div className={styles.informationView}>
            <h2>Політика конфіденційності</h2>
            <p>
                Справжня Політика конфіденційності персональних даних (далі - «Політика конфіденційності») розроблена відповідно до вимог «Про
                персональні дані» та діє відносно всієї інформації, яку Інтернет-магазин «ЇМ РИБУ І ХУДНУ», розташований на доменному імені
                emrybu.shop (далі - «Інтернет-магазин»), може отримати про Користувача під час використання сайту Інтернет-магазину, програм і
                продуктів Інтернет-магазину.
            </p>
            <ol>
                <li>
                    <h3>ВИЗНАЧЕННЯ ТЕРМІНІВ</h3>
                    <ol>
                        <li>
                            У цій Політиці конфіденційності використовуються такі терміни:
                            <ol>
                                <li>
                                    «Адміністрація сайту Інтернет-магазину (далі -«Адміністрація сайту») - уповноважені на управління сайтом
                                    співробітники, які організовують і (або) здійснюють обробку персональних даних, а також визначає цілі обробки
                                    персональних даних, склад персональних даних, що підлягають обробці, дії (операції ), що здійснюються з
                                    персональними даними.
                                </li>
                                <li>
                                    «Персональні дані» - будь-яка інформація, що відноситься прямо або побічно до певного або визначається фізичній
                                    особі (суб'єкту персональних даних).
                                </li>
                                <li>
                                    «Обробка персональних даних» - будь-яка дія (операція) або сукупність дій (операцій), що здійснюються з
                                    використанням засобів автоматизації або без використання таких засобів з персональними даними, включаючи збір,
                                    запис, систематизацію, накопичення, зберігання, уточнення (оновлення, зміна), витяг, використання, передачу
                                    (поширення, надання, доступ), знеособлення, блокування, видалення, знищення персональних даних.
                                </li>
                                <li>
                                    «Конфіденційність персональних даних» - обов'язкове для дотримання оператором або іншим отримав доступ до
                                    персональних даних особою вимога не допускати їх поширення без згоди суб'єкта персональних даних або наявності
                                    іншого законного підстави.
                                </li>
                                <li>
                                    «Користувач сайту Інтернет-магазину» (далі - «Користувач») - особа, яка має доступ до Сайту, за допомогою мережі
                                    Інтернет і використовує Сайт інтернет-магазину.
                                </li>
                                <li>
                                    «Cookies» - невеликий фрагмент даних, відправлений веб-сервером і зберігається на комп'ютері користувача, який
                                    веб-клієнт або веб-браузер кожен раз пересилає веб-серверу в HTTP-запиті при спробі відкрити сторінку відповідного
                                    сайту.
                                </li>
                                <li>«IP-адреса» - унікальний мережеву адресу вузла в комп'ютерній мережі, побудованої за протоколом IP.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>ЗАГАЛЬНІ ПОЛОЖЕННЯ</h3>
                    <ol>
                        <li>
                            Використання Користувачем сайту Інтернет-магазину означає згоду з цією Політикою конфіденційності та умовами обробки
                            персональних даних Користувача.
                        </li>
                        <li>У разі незгоди з умовами Політики конфіденційності Користувач повинен припинити використання сайту Інтернет-магазину.</li>
                        <li>
                            Справжня Політика конфіденційності застосовується лише до сайту Інтернет-магазину «ЇМ РИБУ І ХУДНУ». Інтернет-магазин не
                            контролює і не несе відповідальність за сайти третіх осіб, на які Користувач може перейти по посиланнях, доступним на
                            сайті інтернет-магазину.
                        </li>
                        <li>Адміністрація сайту не перевіряє достовірність персональних даних, що надаються Користувачем сайту Інтернет-магазину.</li>
                    </ol>
                </li>
                <li>
                    <h3>ПРЕДМЕТ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</h3>
                    <ol>
                        <li>
                            Справжня Політика конфіденційності встановлює зобов'язання адміністрації сайту Інтернет-магазину з нерозголошення и
                            забезпечення режиму захисту конфіденційності персональних даних, Які Користувач повинен за вимоги надати адміністрації
                            сайту при реєстрації на сайті Інтернет-магазину або під час оформленні замовлення на послуги громадського харчування.
                        </li>
                        <li>
                            Персональні дані, дозволені до ОБРОБКИ в рамках цієї Політики конфіденційності, Надаються Користувачем Шляхом Заповнення
                            реєстраційної форми на сайті інтернет-магазину «ЇМ РИБУ І ХУДНУ» і містять в собі Наступну інформацію:
                            <ol>
                                <li>прізвище, ім'я, по батькові Користувача;</li>
                                <li>контактний телефон Користувача;</li>
                                <li>адреси Електронної пошта (e-mail);</li>
                                <li>адреси Надання послуг громадського харчування (доставки кулінарних виробів);</li>
                                <li>прізвище, ім'я, по батькові одержувача замовлення.</li>
                            </ol>
                        </li>
                        <li>
                            Інтернет-магазин захищає дані, Які автоматично передаються в процесі перегляду рекламних блоків и при відвідуванні
                            сторінок, на яких встановлено статистичний скрипт системи ( "піксель"):
                            <ul>
                                <li>IP адреси;</li>
                                <li>Інформація з cookies;</li>
                                <li>Інформація про браузер (чи Іншої програми, яка Здійснює доступ до показу реклами);</li>
                                <li>Час доступу;</li>
                                <li>Адреса сторінки, на Якій розташованій рекламний блок;</li>
                                <li>Реферер (адреси попередньої Сторінки).</li>
                            </ul>
                            <ol>
                                <li>
                                    Відключення cookies може спричинитися неможливість доступу до частин сайту Інтернет-магазину, что вимагають
                                    авторизації.
                                </li>
                                <li>
                                    Інтернет-магазин Здійснює збір статистики про IP-адреси своих відвідувачів. Дана інформація використовується з
                                    метою Виявлення та вирішенню технічних проблем, для контролю законності проведення ФІНАНСОВИХ платежів.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Будь-яка Інша персональна інформація НЕ обговорені вище (історія покупок, використовувані браузери и операційні системи и
                            т.д.) підлягає надійному зберіганню та нерозповсюдження, за винятком випадків, передбачення в п.п. 5.2. и 5.3. цієї
                            Політики конфіденційності.
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>ЦІЛІ ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ КОРИСТУВАЧА</h3>
                    <ol>
                        <li>
                            Персональні дані Користувача Адміністрація сайту інтернет-магазину може використовувати в цілях:
                            <ol>
                                <li>
                                    Ідентифікації Користувача, зареєстрованого на сайті Інтернет-магазину, для оформлення замовлення на послуги
                                    громадського харчування.
                                </li>
                                <li>Надання Користувачеві доступу до персоналізованих ресурсів Сайту інтернет-магазину.</li>
                                <li>
                                    Встановлення з Користувачем зворотного зв'язку, включаючи напрямок повідомлень, запитів, що стосуються
                                    використання Сайту інтернет-магазину, надання послуг, обробка запитів і заявок від Користувача.
                                </li>
                                <li>
                                    Створення облікового запису для здійснення замовлень, якщо Користувач дав згоду на створення облікового запису.
                                </li>
                                <li>Повідомлення Користувача Сайту інтернет-магазину про стан Замовлення.</li>
                                <li>Обробки і отримання платежів.</li>
                                <li>
                                    Надання Користувачеві ефективної клієнтської і технічної підтримки при виникненні проблем, пов'язаних з
                                    використанням Сайту інтернет-магазину.
                                </li>
                                <li>
                                    Надання Користувачеві інформації про оновлення продукції, спеціальні пропозиції, інформації про ціни, розсилки
                                    новин та інших відомостей від імені Інтернет-магазину або від імені партнерів Інтернет-магазину.
                                </li>
                                <li>
                                    Здійснення рекламних заходів, спрямованих на привернення уваги Користувача до продукції і послуг
                                    Інтернет-магазину.
                                </li>
                                <li>
                                    Надання доступу Користувачеві на сайти або сервіси партнерів Інтернет-магазину з метою отримання продуктів,
                                    оновлень і послуг.
                                </li>
                                <li>
                                    здійснення маркетингових заходів, в т.ч. оцінки рівня обслуговування, моніторингу трафіку і показника популярності
                                    різних продуктів і послуг.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>СПОСОБИ І ТЕРМІНИ ОБРОБКИ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ</h3>
                    <ol>
                        <li>
                            Обробка персональних даних Користувача здійснюється без обмеження терміну, будь-яким законним способом, в тому числі в
                            інформаційних системах персональних даних з використанням засобів автоматизації або без використання таких засобів.
                        </li>
                        <li>
                            Користувач погоджується з тим, що Адміністрація сайту має право передавати персональні дані третім особам, зокрема,
                            кур'єрським службам, організаціями поштового зв'язку, операторам електрозв'язку, з метою виконання замовлення Користувача,
                            оформленого на Сайті інтернет-магазину «ЇМ РИБУ І ХУДНУ», включаючи доставку замовлених кулінарних виробів, а також з
                            метою інформування та привернення уваги Користувача про умови надання послуг, включаючи рекламні акції, нової продукції, .
                        </li>
                        <li>
                            Персональні дані Користувача можуть бути передані уповноваженим органам державної влади України тільки на підставах та в
                            порядку, встановленим законодавством України.
                        </li>
                        <li>
                            При втраті або розголошення персональних даних Адміністрація сайту інформує Користувача про втрату або розголошення
                            персональних даних.
                        </li>
                        <li>
                            Адміністрація сайту вживає необхідних організаційних і технічних заходів для захисту персональної інформації Користувача
                            від неправомірного або випадкового доступу, знищення, перекручення, блокування, копіювання, поширення, а також від інших
                            неправомірних дій третіх осіб.
                        </li>
                        <li>
                            Адміністрація сайту спільно з Користувачем вживає всіх необхідних заходів щодо запобігання збиткам або інших негативних
                            наслідків, викликаних втратою або розголошенням персональних даних Користувача.
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>ЗГОДУ НА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ</h3>
                    <ol>
                        <li>Збір та обробка персональний даних здійснюється за згодою користувачів, крім випадків, встановлених законом.</li>
                        <li>
                            Згода Користувача на обробку персональних даних вважається одержаним при наданні їм персональних даних вільно, своєю волею
                            і в своєму інтересі і підтверджується шляхом натискання кнопки «З Правилами надання послуг ознайомлений і згоден» при
                            реєстрації на сайті.
                        </li>
                        <li>Незгода з обробкою персональних даних виражається шляхом відмови від використання сайту.</li>
                        <li>
                            Користувач має право скасувати раніше дану згоду на обробку персональних даних шляхом подання відповідного повідомлення за
                            формою зворотного зв'язку, розміщеної на сайті. У разі отримання повідомлення про скасування згоди на обробку персональних
                            даних Користувача Адміністрація сайту приймає негайні заходи до виключення персональних даних Користувача з бази даних і
                            припиняє їх обробку будь-яким способом, крім випадків, передбачених законом.
                        </li>
                        <li>
                            У разі якщо скасування згоди на обробку персональних даних здійснюється Користувачем до виконання розміщеного і
                            підтвердженого замовлення, використання персональних даних припиняється після виконання замовлення.
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>ЗОБОВ'ЯЗАННЯ ЩОДО ЗАХИСТУ ПЕРСОНАЛЬНИХ ДАНИХ</h3>
                    Адміністрація сайту зобов'язується:
                    <ol>
                        <li>Використовувати отриману інформацію виключно для цілей, зазначених у п. 4 цієї Політики конфіденційності.</li>
                        <li>
                            Забезпечити зберігання конфіденційної інформації в таємниці, не розголошувати без попередньої письмової згоди Користувача,
                            а також не здійснювати продаж, обмін, опублікування, або розголошення іншими можливими способами переданих персональних
                            даних Користувача, за винятком п.п. 5.2 і 5.3 цієї Політики Конфіденційності.
                        </li>
                        <li>
                            Вживати заходів обережності для захисту конфіденційності персональних даних Користувача згідно з порядком, який зазвичай
                            використовується для захисту такого роду інформації в існуючому діловому обороті.
                        </li>
                        <li>
                            Здійснити блокування персональних даних, що відносяться до відповідного Користувачеві, з моменту звернення або запиту
                            Користувача або його законного представника або уповноваженого органу з захисту прав суб'єктів персональних даних на
                            період перевірки, в разі виявлення недостовірних персональних даних або неправомірних дій.
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>ЗАХОДИ ЩОДО ЗАБЕЗПЕЧЕННЯ БЕЗПЕКИ ПЕРСОНАЛЬНИХ ДАНИХ ПРИ ЇХ ОБРОБКИ</h3>
                    <ol>
                        <li>
                            При обробці персональних даних Адміністрація сайту приймає необхідні правові, організаційні та технічні заходи для захисту
                            персональних даних від неправомірного або випадкового доступу до них, знищення, перекручення, блокування, копіювання,
                            надання, поширення персональних даних, а також від інших неправомірних дій у відношенні персональних даних.
                            <br />
                            Адміністрація сайту забезпечує запис, систематизацію, накопичення, зберігання, уточнення (оновлення, зміна), витяг
                            персональних даних громадян.
                        </li>
                        <li>
                            З метою захисту персональних даних Адміністрація сайту робить наступні заходи:
                            <p>
                                Ідентифікація даних:
                                <br />
                                - Ідентифікація і аутентифікація користувачів, які є працівниками оператора
                                <br />
                                - Управління ідентифікаторами, в тому числі створення, привласнення, знищення ідентифікаторів
                                <br />
                                - Управління засобами аутентифікації, в тому числі зберігання, видача, ініціалізація, блокування коштів аутентифікації
                                і вжиття заходів у разі втрати і (або) компрометації засобів аутентифікації
                                <br />
                                - Ідентифікація і аутентифікація користувачів, які не є працівниками оператора (зовнішніх користувачів)
                                <br />- Захист зворотного зв'язку при введенні аутентифікаційної інформації
                            </p>
                            <p>
                                Управління доступом суб'єктів доступу до об'єктів доступу
                                <br />
                                - Управління (заклад, активація, блокування і знищення) обліковими записами користувачів
                                <br />
                                - Реалізація необхідних методів, типів і правил розмежування доступу
                                <br />
                                - Управління інформаційними потоками між пристроями, сегментами інформаційної системи, а також між інформаційними
                                системами
                                <br />
                                - Розподіл повноважень (ролей) користувачів, адміністраторів та осіб, які забезпечують функціонування інформаційної
                                системи
                                <br />
                                - Призначення мінімально необхідних прав і привілеїв користувачам, адміністраторам і особам, які забезпечують
                                функціонування інформаційної системи
                                <br />
                                - Обмеження неуспішних спроб входу в інформаційну систему
                                <br />
                                - Блокування сеансу доступу в інформаційну систему після встановленого часу бездіяльності (неактивності) користувача
                                або за його запитом
                                <br />
                                - Дозвіл (заборона) дій користувачів, дозволених до ідентифікації і аутентифікації
                                <br />
                                - Реалізація захищеного віддаленого доступу суб'єктів доступу до об'єктів доступу через зовнішні
                                інформаційно-телекомунікаційні мережі
                                <br />
                                - Регламентація і контроль використання в інформаційній системі технологій бездротового доступу
                                <br />
                                - Регламентація і контроль використання в інформаційній системі мобільних технічних засобів
                                <br />- Управління взаємодією з інформаційними системами сторонніх організацій
                            </p>
                            <p>
                                Реєстрація подій безпеки
                                <br />
                                - Визначення подій безпеки, які підлягають реєстрації, і термінів їх зберігання
                                <br />
                                - Визначення складу і змісту інформації про події безпеки, які підлягають реєстрації
                                <br />
                                - Збір, запис і зберігання інформації про події безпеки протягом встановленого часу зберігання
                                <br />- Захист інформації про події безпеки
                            </p>
                            <p>
                                Антивірусний захист:
                                <br />
                                - Реалізація антивірусного захисту
                                <br />- Оновлення бази даних ознак шкідливих комп'ютерних програм (вірусів)
                            </p>
                            <p>
                                Контроль (аналіз) захищеності персональних даних:
                                <br />
                                - Виявлення, аналіз вразливостей інформаційної системи і оперативне усунення нововиявлених вразливостей
                                <br />
                                - Контроль установки оновлень програмного забезпечення, включаючи оновлення програмного забезпечення засобів захисту
                                інформації
                                <br />
                                - Контроль працездатності, параметрів настройки і правильності функціонування програмного забезпечення і засобів
                                захисту інформації
                                <br />- Контроль складу технічних засобів, програмного забезпечення і засобів захисту інформації
                            </p>
                            <p>
                                Захист середовища віртуалізації:
                                <br />
                                - Ідентифікація і аутентифікація суб'єктів доступу і об'єктів доступу в віртуальної інфраструктури, в тому числі
                                адміністраторів управління коштами віртуалізації
                                <br />
                                - Управління доступом суб'єктів доступу до об'єктів доступу в віртуальної інфраструктури, в тому числі всередині
                                віртуальних машин
                                <br />
                                - Реєстрація подій безпеки у віртуальному інфраструктурі
                                <br />
                                - Реалізація та управління антивірусним захистом у віртуальній інфраструктурі
                                <br />- Розбиття віртуальної інфраструктури на сегменти
                            </p>
                            <p>
                                Захист технічних засобів:
                                <br />- Контроль і управління фізичним доступом до технічних засобів, засобів захисту інформації, засобів забезпечення
                                функціонування
                            </p>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    );
}

export default Privacy;
