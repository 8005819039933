import { NavLink } from 'react-router-dom';
import { RxInfoCircled } from 'react-icons/rx';
import { TbShoppingBag } from 'react-icons/tb';
import { FaRegHeart } from 'react-icons/fa';
import { MdOutlinePlaylistAddCheck } from 'react-icons/md';
import style from './BurgerMenu.module.css';
import { useContext } from 'react';
import AppContext from '../../context/appContext';

function BurgerMenu({ showBMenu, active }) {
    const { identified } = useContext(AppContext);
    const navItems = [
        { to: 'cart', icon: <TbShoppingBag />, label: 'Кошик' },
        { to: 'favorite', icon: <FaRegHeart />, label: 'Мої улюблені страви', requiresClient: true },
        { to: 'orders', icon: <MdOutlinePlaylistAddCheck />, label: 'Мої замовлення', requiresClient: true },
        { to: 'contacts', icon: <RxInfoCircled />, label: 'Контакти' },
        { to: 'delivery-and-pay', icon: <RxInfoCircled />, label: 'Доставка і оплата' },
        { to: 'rules', icon: <RxInfoCircled />, label: 'Правила надання послуг' },
        { to: 'privacy-policy', icon: <RxInfoCircled />, label: 'Політика конфіденційності' },
    ];
    // { to: 'info', icon: <RxInfoCircled />, label: 'Інформація для доставки' },

    return (
        <div className={`${style.menu} ${active ? style.active : ''}`} onClick={showBMenu}>
            <div className={style.blur}>
                <div className={style.menuContent} onClick={(e) => e.stopPropagation()}>
                    <div className={style.closeMenu} onClick={showBMenu}></div>
                    <nav className={style.navList}>
                        {navItems.map(
                            ({ to, icon, label, requiresClient }, index) =>
                                (!requiresClient || identified) && ( // Проверяем, нужно ли наличие клиента
                                    <NavLink key={index} to={to} className={style.navItem} onClick={showBMenu}>
                                        <div className={style.navContent}>
                                            {icon}
                                            {label}
                                        </div>
                                    </NavLink>
                                )
                        )}
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default BurgerMenu;
