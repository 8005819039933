import React, { useContext } from 'react';
import style from './Address.module.css';
import AppContext from '../context/appContext';

const Address = ({ address, handleFormChange }) => {
    const { cities } = useContext(AppContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        handleFormChange({ target: { name: `address.${name}`, value } });
    };

    return (
        <div className={style.container}>
            <select name="city" value={address.city} onChange={handleChange} required>
                <option value="" disabled>
                    Оберіть місто
                </option>
                {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                        {city.description}
                    </option>
                ))}
            </select>
            <input type="text" name="street" placeholder="Вулиця" value={address.street} onChange={handleChange} required />
            <input type="text" name="house" placeholder="Будинок" value={address.house} onChange={handleChange} required />
            <input type="number" name="entrance" placeholder="Під'їзд" value={address.entrance} onChange={handleChange} />
            <input type="number" name="floor" placeholder="Поверх" value={address.floor} onChange={handleChange} />
            <input type="number" name="apartment" placeholder="Квартира" value={address.apartment} onChange={handleChange} />
        </div>
    );
};

export default Address;
