import { FiPhone } from 'react-icons/fi';
import { FaViber, FaTelegram } from 'react-icons/fa';
import style from './Contacts.module.css';

function Contacts({ contacts = false }) {
    const phoneNumbers = [
        { number: '+380634441188', label: '063-444-11-88' },
        { number: '+380504441188', label: '050-444-11-88' },
        { number: '+380636780264', label: '063-678-02-64' },
    ];

    return (
        <div className={style.content}>
            {contacts && <h2>Контакти</h2>}
            <h4>Прийом замовлень 10:00 - 21:00</h4>
            <h4>Доставка замовлень 11:30 - 23:00</h4>
            <div className={style.contacts}>
                {phoneNumbers.map(({ number, label }) => (
                    <div className={style.phone} key={number}>
                        <FiPhone />
                        <span className={style.linkNumber} onClick={() => window.open(`tel:${number}`)}>
                            <strong>{label}</strong>
                        </span>
                    </div>
                ))}
                <div className={style.messagers}>
                    <FaViber style={{ marginRight: '5px', color: '#7b519b' }} />
                    <FaTelegram style={{ color: '#22a9e8' }} />
                    <span className={style.linkNumber} onClick={() => window.open(`tel:${phoneNumbers[2].number}`)}>
                        <strong>{phoneNumbers[2].label}</strong>
                    </span>
                </div>
            </div>
            {contacts && (
                <>
                    <h2>Наші адреси:</h2>
                    <p>1. Київ, Поділ, вул. Воздвиженська 20</p>
                    <p>2. Київ, вул. Деміївська 13</p>
                </>
            )}
        </div>
    );
}

export default Contacts;
