import CategoryMenuItem from './CategoryMenuItem';
import style from './CategoryMenu.module.css';
import appContext from '../../context/appContext';
import { useContext, useEffect, useState } from 'react';

function CategoryMenu() {
    const [dataCategories, setCategories] = useState([]);
    const { categories } = useContext(appContext);

    useEffect(() => {
        if (!categories.length) return;

        const [allCategories, ...restCategories] = categories;
        const sortedCategories = restCategories.sort((a, b) => a.description.localeCompare(b.description));
        setCategories([allCategories, ...sortedCategories]);
    }, [categories]);

    return (
        <nav className={style.categoryMenu}>
            {dataCategories.map((item) => (
                <CategoryMenuItem item={item} key={item.id} />
            ))}
        </nav>
    );
}

export default CategoryMenu;
