import React, { useContext, useState } from 'react';
import style from './Location.module.css';
import appContext from '../context/appContext';

function Location({ handleFormChange }) {
    const { locations } = useContext(appContext);
    const [selectedLocation, setSelectedLocation] = useState(locations[0]?.id || '');

    const handleChange = (event) => {
        setSelectedLocation(event.target.value);
        handleFormChange(event);
    };

    return (
        <div className={style.container}>
            {locations.map(({ id, desc }) => (
                <div className={style.switchLocation} key={id}>
                    <input
                        type="radio"
                        id={id}
                        name="location"
                        value={id}
                        onChange={handleChange}
                        checked={selectedLocation === id}
                        className={style.toggleLocation}
                    />
                    <label htmlFor={id} className={style.toggleLocationLabel}>
                        {desc}
                    </label>
                </div>
            ))}
        </div>
    );
}

export default Location;
