import styles from './Rules.module.css';
function Rules() {
    return (
        <div className={styles.informationView}>
            <h2>Правила надання послуг</h2>
            <ol>
                <li>
                    <h3>Основні поняття</h3>
                    Поняття і визначення, що використовуються в цих Правилах надання послуг з приготування і доставки страв на замовлення клієнтів
                    (далі - «Правила»), вживаються в наступних значеннях:
                    <br />
                    <b>«Виконавець», «ЇМ РИБУ І ХУДНУ»</b> — ФОП Кот Олександр Іванович, безпосередньо або через залучених осіб надає послуги з
                    приготування готових страв і їх доставці на замовлення Клієнтів.
                    <br />
                    <b>«Клієнт»</b> — особа, кому надаються послуги з приготування і доставки страв для власного споживання.
                    <br />
                    <b>«Одержувач Замовлення»</b> — особа, яка отримує приготовлену і доставлену на замовлення Клієнта продукцію.
                    <br />
                    <b>«Сайт»</b> — сукупність комп'ютерних програм, що забезпечують публікацію даних, що стосуються послуг, продукції Виконавця
                    шляхом повідомлення їх для загального відома за допомогою технічних засобів зв'язку в мережі Інтернет за адресою emrybu.shop.
                    <br />
                    <b>«Послуга»</b> — сукупність дій Виконавця з приготування, пакування та доставку Продукції, замовленої Клієнтом.
                    <br />
                    <b>«Продукція»</b> — страви і супутні товари, зазначені на Сайті Виконавця і доступні для замовлення Клієнтом.
                    <br />
                    <b>«Замовлення»</b> — сукупність дій Клієнта за висловом наміри отримати від Виконавця послугу з приготування і доставки готових
                    страв і супутніх товарів, замовлені для приготування і доставки. Під Замовленням розуміється також комплект страв і супутніх
                    товарів, замовлених Клієнтом.
                </li>
                <li>
                    <h3>Оформлення та терміни виконання Замовлення</h3>
                    <ol>
                        <li>
                            Замовлення Клієнта може бути оформлений Клієнтом самостійно на Сайті, в мобільних додатках або по телефону за допомогою
                            оператора Call-центру.
                        </li>
                        <li>
                            Оформлення Замовлення через Сайт.
                            <p>При оформленні Замовлення Клієнт повинен вказати наступну інформацію:</p>
                            <ul>
                                <li>Ім'я</li>
                                <li>Контактний телефон</li>
                                <li>Адреса доставки: назва міста, вулиці, номер будинку і квартири (офісу)</li>
                                <li>Прізвище, ім'я, по батькові отримувача передплаченого замовлення.</li>
                            </ul>
                        </li>
                        <li>
                            Оформлення Замовлення по телефону в Call-центр за багатоканальним телефоном у своєму місті або по телефону і оператори
                            проконсультують Клієнта за асортиментом і оформлять Замовлення.
                            <ol>
                                <li>
                                    Всі інформаційні матеріали, представлені на Сайті, носять довідковий характер і не можуть в повній мірі передавати
                                    достовірну інформацію про властивості та характеристики Продукції, включаючи колір, розмір і форму. У разі
                                    виникнення у Клієнта питань, що стосуються властивостей і характеристик Продукції, перед оформленням Замовлення
                                    Клієнт повинен звернутися до Виконавця.
                                </li>
                                <li>
                                    У разі відсутності можливості з приготування замовленої Продукції у Виконавця, Виконавець має право анулювати
                                    Замовлення в частині зазначеної Продукції і повідомити про це Клієнта по телефону.
                                </li>
                                <li>
                                    У разі анулювання повністю або частково передплаченого Замовлення вартість анульованою Продукції повертається
                                    Виконавцем Клієнту тим способом, яким Продукція спочатку була оплачено.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Мінімальна сума замовлення складає від 300 до 500 грн і може бути змінена Виконавцем в залежності від адреси доставки чи у
                            святкові дні.
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Доставка</h3>
                    <ol>
                        <li>
                            Доставка здійснюється безкоштовно в межах територіальних зон доставки, визначених Виконавцем. Доставка в заміські будинки
                            здійснюється за наявності під'їзних шляхів.
                        </li>
                        <li>Служба доставки компанії Виконавця працює цілодобово або в іншому режимі, зазначеному для конкретного міста.</li>
                        <li>
                            Орієнтовний термін доставки - від 60 хвилин. Конкретний термін доставки визначається з урахуванням віддаленості Клієнта,
                            дорожньої ситуації, погодних умов та інших, що не залежать від Виконавця обставин, і повідомляється оператором при прийомі
                            замовлення. Перевищення встановленого терміну доставки можливо з огляду на обставини непереборної сили.
                        </li>
                        <li>
                            Якщо будь-які непередбачені обставини заважають Клієнту отримати Замовлення, необхідно якомога раніше зв'язатися з
                            операторами Call-центру за телефоном і домовитися про перенесення часу або місця доставки.
                            <br />У разі відсутності Клієнта / отримувача в призначений час за адресою доставки Кур'єр очікує Клієнта / отримувача
                            протягом 5 хвилин.
                        </li>
                        <li>
                            Якщо замовлення не виконано Виконавцем протягом зазначеного при оформленні замовлення терміну, Клієнт повинен повідомити
                            про це або по телефону оператору, або шляхом подачі письмового повідомлення або по електронній пошті:
                            emrybu.com.ua@gmail.com не пізніше 24 годин після закінчення терміну доставки замовлення.
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Передача Замовлення</h3>
                    При доставці замовлення передається Клієнту / Одержувачу.
                    <br />
                    Клієнт / Одержувач повинен перевірити комплектацію Замовлення і в разі відсутності претензій до комплектності та кількості -
                    оплатити замовлення.
                    <br />
                    При передачі передплаченого замовлення оформляється Акт приймання замовлення, що підписується кур'єром і Замовником / Одержувачем.
                    <br />
                    Оплата Продукції при отриманні замовлення або підписання Акту при отриманні передплаченого замовлення є підтвердженням Клієнтом
                    факту належного надання послуги з приготування і доставки Продукції.
                </li>
                <li>
                    <h3>Оплата Послуг</h3>
                    <ol>
                        <li>
                            Вартість послуг з приготування і доставки Продукції вказана на Сайті і може бути змінена Виконавцем в односторонньому
                            порядку. При цьому ціна на вже замовлену клієнтом Продукцію зміні не підлягає.
                        </li>
                        <li>
                            Способи оплати Продукції:
                            <ul>
                                <li>Оплата готівкою при отриманні замовлення</li>
                            </ul>
                        </li>
                        <li>
                            Виконавець має право надавати Клієнту знижки на Продукцію. Види і розміри знижок, порядок і умови їх надання вказані в
                            розділі АКЦІЇ і можуть бути змінені Виконавцем в односторонньому порядку.
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Відмова від послуг</h3>
                    <ol>
                        <li>
                            Відповідно до законодавства України повернення продовольчої Продукції <b>належної якості</b> не допускається, повернення
                            грошових коштів за таку Продукцію не проводиться.
                        </li>
                        <li>
                            Повернення Продукції неналежної якості
                            <br />У разі якщо Клієнт виявить, що йому передана Продукція неналежної якості, він має право{' '}
                            <b>до закінчення терміну придатності</b> повернути Продукцію неналежної якості Виконавцю і зажадати повернення сплаченої
                            грошової суми, або вимагати заміни Продукції неналежної якості.
                            <br />
                            При цьому вартість Продукції повертається Клієнту протягом 10 днів з моменту отримання Виконавцем заяви Клієнта.
                        </li>
                        <li>
                            Порядок дій при пересорті
                            <br />У разі виявлення в замовленні Продукції, що не відповідає замовленої, Клієнт має право прийняти дану Продукцію або
                            відмовитися від неї і вимагати заміни на Продукцію, передбачену Замовленням, або повернення грошових коштів за фактично не
                            одержану Продукцію.
                        </li>
                        <li>Заміна Продукції, що не відповідає Замовленню за асортиментом, здійснюється шляхом оформлення нового замовлення.</li>
                        <li>
                            Порядок дій при доставці в повному обсязі скомплектованого замовлення
                            <br />
                            При доставці меншої кількості Продукції, ніж визначено Замовленням, Клієнт має право прийняти Продукцію в частині, що
                            відповідає Замовленню, і зажадати передати відсутню кількість Продукції або, якщо відсутня Продукція була оплачена,
                            відмовитися від Замовлення в частині недостатньої Продукції і вимагати повернення грошових коштів за відсутню Продукцію.
                            Даний факт недовкладання Продукції оформляється Актом в довільній формі, який підписується Клієнтом / Одержувачем і
                            кур'єром.
                            <br />
                            Доставка відсутньої Продукції здійснюється за допомогою оформлення нового Замовлення.
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Гарантії та відповідальність</h3>
                    <ol>
                        <li>
                            Продукція може бути використана Клієнтом тільки до закінчення терміну придатності Продукції. Виконавець не несе
                            відповідальності за шкоду, заподіяну Клієнту внаслідок неналежного використання, зберігання, споживання Продукції.
                        </li>
                        <li>Виконавець не несе відповідальності за зміст і функціонування інших сайтів, крім emrybu.shop</li>
                        <li>Клієнт зобов'язується не використовувати замовлену Продукцію в підприємницьких цілях.</li>
                    </ol>
                </li>
                <li>
                    <h3>Конфіденційність і захист персональної інформації</h3>
                    <ol>
                        <li>
                            Надаючи свої персональні дані при замовленні Продукції або реєстрації на Сайті, Клієнт погоджується на їх обробку
                            Виконавцем, в тому числі і з метою просування Продукції і послуг.
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    );
}

export default Rules;
