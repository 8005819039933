import React from 'react';
import style from './Logistics.module.css';
import Location from './Location';
import Address from './Address';

function Logistics({ address, handleFormChange, deliveryOption }) {
    const isPickup = deliveryOption === 'pickup';

    const toggleDeliveryOption = (option) => {
        handleFormChange({ target: { name: 'deliveryOption', value: option } });
    };

    return (
        <div className={style.container}>
            <div className={style.checkboxGroup}>
                <input
                    type="checkbox"
                    id="pickup"
                    className={style.checkboxPickup}
                    checked={isPickup}
                    onChange={() => toggleDeliveryOption('pickup')}
                />
                <label htmlFor="pickup" className={style.labelPickup}>
                    Самовивіз
                </label>
            </div>
            {isPickup && <Location handleFormChange={handleFormChange} />}
            <div className={style.checkboxGroup}>
                <input
                    type="checkbox"
                    id="delivery"
                    className={style.checkboxDelivery}
                    checked={!isPickup}
                    onChange={() => toggleDeliveryOption('delivery')}
                />
                <label htmlFor="delivery" className={style.labelDelivery}>
                    Доставка
                </label>
            </div>
            {!isPickup && <Address address={address} handleFormChange={handleFormChange} />}
        </div>
    );
}

export default Logistics;
