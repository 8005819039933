import { createContext } from 'react';

const initialContext = {
    categories: [],
    products: [],
    setIsFavorite: () => {},
    cart: [],
    setCart: () => {},
    locations: [],
    cities: [],
    customer: () => {},
    identified: false,
};

const AppContext = createContext(initialContext);

export default AppContext;
