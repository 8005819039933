// Loading.jsx
import React from 'react';
import style from './Loading.module.css'; // Импортируйте CSS-модуль

function Loading() {
    return (
        <div className={style.loadingContainer}>
            <h2>Завантаження</h2>
            <div className={style.loadingRing}></div>
        </div>
    );
}

export default Loading;
