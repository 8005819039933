import React from 'react';
import Product from '../product/Product';
import style from './SearchResults.module.css';

function SearchResults({ results }) {
    return (
        <div className={style.resultsContainer}>
            {results.map((item) => (
                <div className={style.resultItem} key={item.id}>
                    <Product item={item} />
                </div>
            ))}
        </div>
    );
}

export default SearchResults;
