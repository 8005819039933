import templateCategories from './categories';
import menu from './menu';

export const fetchCategoriesTemplate = () => templateCategories;

export const fetchDataMenuTemplate = () => menu.map((item) => ({ ...item, amount: 0 }));

export async function fetchData(url, method = 'GET', data = null, headers = {}) {
    if (!url) {
        throw new Error('URL is required');
    }

    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        ...(data && { body: JSON.stringify(data) }),
    };

    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}

export async function getIPFromAmazon() {
    try {
        const response = await fetch('https://checkip.amazonaws.com/');
        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const ip = await response.text();
        alert(ip);
    } catch (error) {
        console.error('IP fetch error:', error);
    }
}
