import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import appContext from '../../context/appContext';
import { fetchData } from '../../data/fetchData';
import CartProducts from './CartProducts';
import Logistics from '../../UI/Logistics';
import Payment from '../../UI/Payment';
import style from './Cart.module.css';
import DateTimePicker from '../../UI/DateTimePicker';

const getInitialFormData = (customer, locations) => ({
    address: customer.address,
    deliveryOption: customer.deliveryOption,
    location: customer.location || locations[0]?.id || '',
    deliveryDate: getDeliveryDate(),
    deliveryTiming: 'nearest',
    timeInterval: '',
    payment: 'cash',
    paid: false,
    comment: '',
    noCallback: true,
    birthday: false,
    finalSum: 0,
    discount: 0,
});

const getDeliveryDate = () => {
    const today = new Date();
    return today.toLocaleDateString('uk-UA', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('.').reverse().join('-');
};

function Cart({ newOrderUrl, initData }) {
    const { cart, setCart, locations, related, customer } = useContext(appContext);
    const [totalSum, setSum] = useState(0);
    const [finalSum, setFinalSum] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [amount, setAmount] = useState(0);
    const [accepted, setAccepted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState(getInitialFormData(customer, locations));
    const [clientSelected, setClientSelected] = useState(false);

    const addRelatedProducts = useCallback(
        (products) => {
            products.forEach((relatedProduct) => {
                const existsInCart = cart.some((item) => item.id === relatedProduct.id);
                if (!existsInCart) {
                    setCart((prevCart) => [
                        ...prevCart,
                        {
                            id: relatedProduct.id,
                            price: relatedProduct.price,
                            amount: 0,
                            sum: 0,
                            imgUrl: relatedProduct.imgUrl,
                            title: relatedProduct.title,
                            related: true,
                            discountForbidden: true,
                        },
                    ]);
                }
            });
        },
        [cart, setCart]
    );

    useEffect(() => {
        if (!!customer && customer.identified) setClientSelected(true);
    }, [customer]);

    useEffect(() => {
        if (cart.length > 0) {
            addRelatedProducts(related.chopsticks);
            addRelatedProducts(related.sauces);
        }
    }, [cart, related.chopsticks, related.sauces, addRelatedProducts]);

    const { newSum, newAmount, customerDiscount, newFinalSum } = useMemo(() => {
        let discountAmount = 0;

        if (formData.deliveryOption === 'pickup') {
            discountAmount = customer.customerStatus === 'regular' ? 20 : 15;
        } else {
            if (formData.birthday) {
                discountAmount = customer.customerStatus === 'regular' ? 15 : 10;
            } else if (formData.deliveryTiming !== 'nearest') {
                const currentDate = new Date();
                const fiveHoursLater = new Date(currentDate.getTime() + 5 * 60 * 60 * 1000);
                const deliveryDate = new Date(formData.deliveryDate);
                const [hours, minutes] = formData.timeInterval.split('-')[0].split(':').map(Number);
                deliveryDate.setHours(hours, minutes, 0, 0);
                if (deliveryDate > fiveHoursLater) {
                    discountAmount = customer.customerStatus === 'regular' ? 10 : 7;
                }
            }
        }

        const newSum = cart.reduce((sum, item) => (item.related ? sum : sum + item.sum), 0);
        const newAmount = cart.reduce((amount, item) => amount + item.amount, 0);

        const newFinalSum = cart.reduce((sum, item) => {
            if (item.related) return sum;
            const itemDiscount = item.discount !== 0 ? Math.max(discountAmount, item.discount) : discountAmount;
            return sum + Math.round(item.sum * (1 - itemDiscount / 100));
        }, 0);

        return { newSum, newAmount, customerDiscount: discountAmount, newFinalSum };
    }, [cart, customer, formData.deliveryOption, formData.birthday, formData.deliveryTiming, formData.deliveryDate, formData.timeInterval]);

    useEffect(() => {
        setDiscount(customerDiscount);
        setSum(newSum.toFixed(2));
        setFinalSum(newFinalSum.toFixed(2));
        setAmount(newAmount);
    }, [newSum, newAmount, customerDiscount, newFinalSum]);

    const handlerSum = (sum) => setSum(sum);

    const handleFormChange = ({ target: { name, value, type, checked } }) => {
        if (name.startsWith('address.')) {
            const addressKey = name.split('.')[1];
            setFormData((prev) => ({
                ...prev,
                address: { ...prev.address, [addressKey]: value },
            }));
        } else if (type === 'checkbox') {
            setFormData((prev) => ({ ...prev, [name]: checked }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
        if (name === 'deliveryOption' && value === 'pickup') setFormData((prev) => ({ ...prev, birthday: false }));
        setError('');
    };

    const validateForm = () => {
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }

        const orderData = { ...formData, products: cart, finalSum, discount, initData };

        setLoading(true);
        try {
            const responseData = await fetchData(newOrderUrl, 'POST', orderData);
            if (responseData.success) {
                setAccepted(true);
                setCart([]);
            } else {
                setError(responseData.error || 'Помилка при створенні замовлення. Спробуйте ще раз.');
            }
        } catch (error) {
            setError('Сталася помилка при відправці замовлення. Будь ласка, спробуйте ще раз.');
        } finally {
            setLoading(false);
        }
    };

    const handlePay = () => {
        console.log('Оплата производится');
    };

    return (
        <div className={style.container}>
            <h2>ОФОРМЛЕННЯ ЗАМОВЛЕННЯ</h2>
            {amount === 0 ? (
                <p>Ваш кошик порожній</p>
            ) : (
                <>
                    <h3>ТОВАРИ В КОШИКУ</h3>
                    <CartProducts cart={cart} setCart={setCart} handlerSum={handlerSum} />
                    <form onSubmit={handleSubmit}>
                        <Logistics address={formData.address} handleFormChange={handleFormChange} deliveryOption={formData.deliveryOption} />
                        <DateTimePicker formData={formData} handleFormChange={handleFormChange} />
                        <Payment dataChange={handleFormChange} />
                        <textarea
                            id="comment"
                            name="comment"
                            value={formData.comment}
                            onChange={handleFormChange}
                            rows="4"
                            className={style.commentInput}
                            placeholder="Ви можете додати свій коментар до замовлення"
                        />
                        <div className={style.checkboxGroup}>
                            <input
                                type="checkbox"
                                id="noCallback"
                                name="noCallback"
                                className={style.checkboxNoCallback}
                                checked={formData.noCallback}
                                onChange={handleFormChange}
                            />
                            <label htmlFor="noCallback" className={style.labelNoCallback}>
                                Не дзвонити
                            </label>
                        </div>
                        {formData.deliveryOption !== 'pickup' && (
                            <div className={style.checkboxGroupBirthday}>
                                <input type="checkbox" id="birthday" name="birthday" checked={formData.birthday} onChange={handleFormChange} />
                                <label htmlFor="birthday" className={style.labelBirthday}>
                                    <span className={`${style.customCheckbox} ${formData.birthday ? style.customCheckboxChecked : ''}`}>
                                        {formData.birthday && <span className={style.checkmark}>✔</span>}
                                    </span>
                                    У мене день народження
                                </label>
                            </div>
                        )}

                        {error && <p className={style.error}>{error}</p>}
                        {totalSum !== finalSum && (
                            <>
                                <h2>Разом {totalSum} ₴</h2>
                                <h2>Знижка {discount} %</h2>
                            </>
                        )}
                        <h2>До сплати {finalSum} ₴</h2>
                        {clientSelected && (
                            <>
                                <div className={style.groupPay}>
                                    <span>Сплатити зараз:</span>
                                    <button type="button" onClick={handlePay} className={style.btnPay}>
                                        <img src="logo_liqpay_for_white.svg" alt="LiqPay" className={style.payImage} />
                                    </button>
                                </div>
                                <button type="submit" disabled={loading} className={style.btnSubmit}>
                                    {loading ? 'Завантаження...' : 'Оформити замовлення'}
                                </button>
                            </>
                        )}
                    </form>
                </>
            )}
            {accepted && <p>Дякуємо за замовлення! Його деталі Ви завжди можете переглянути в розділі «Мої замовлення».</p>}
        </div>
    );
}

export default Cart;
