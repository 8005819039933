import React, { useState } from 'react';
import style from './CartRelatedProduct.module.css';

function CartRelatedProduct({ cartItem, updateAmount, index }) {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleImageClick = () => {
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleDecrease = () => {
        if (cartItem.amount >= 1) {
            const newAmount = cartItem.amount - 1;
            updateAmount(cartItem.id, newAmount, cartItem.price * newAmount);
        }
    };

    const handleIncrease = () => {
        const newAmount = cartItem.amount + 1;
        updateAmount(cartItem.id, newAmount, cartItem.price * newAmount);
    };

    return (
        <div className={index === 1 || index === 4 ? style.lastProduct : style.product}>
            <img src={cartItem.imgUrl} alt={cartItem.title} className={style.productImage} onClick={handleImageClick} />
            <div className={style.productInfo}>
                <div className={style.productName}>{cartItem.title}</div>
                {/* <div className={style.productSum}>
                    <span className={style.sumLabel}>Сума:</span>
                    <span className={style.sumValue}>{cartItem.sum}</span>
                    <span className={style.sumLabel}>₴</span>
                </div> */}
                <div className={style.quantityControls}>
                    <button className={style.btnDecrease} onClick={handleDecrease}>
                        −
                    </button>
                    <span className={style.quantity}>{cartItem.amount}</span>
                    <button className={style.btnIncrease} onClick={handleIncrease}>
                        +
                    </button>
                </div>
            </div>
            {isModalOpen && (
                <div className={style.modalOverlay} onClick={handleCloseModal}>
                    <div className={style.modalContent} onClick={(e) => e.stopPropagation()}>
                        <img src={cartItem.imgUrl} alt="Товар" className={style.modalImage} />
                        <button className={style.closeButton} onClick={handleCloseModal}>
                            ✖
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CartRelatedProduct;
