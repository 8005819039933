import { useContext, useMemo } from 'react';
import style from './AllCategories.module.css';
import { NavLink } from 'react-router-dom';
import appContext from '../../context/appContext';

function AllCategories() {
    const { categories } = useContext(appContext);

    // Используем useMemo для фильтрации категорий
    const filteredCategories = useMemo(() => {
        return categories ? categories.filter((category) => category.slug !== '') : [];
    }, [categories]);

    return (
        <div className={style.container}>
            <h3 className={style.textMenu}>Меню</h3>
            <div className={style.categories}>
                {filteredCategories.map((category) => (
                    <div className={style.category} key={category.id}>
                        <div className={style.categoryContent}>
                            <NavLink to={category.slug} className={style.title}>
                                {category.description}
                                <div className={style.imageContainer}>
                                    <img className={style.itemImage} src={category.imgUrl} alt={category.description} />
                                </div>
                            </NavLink>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AllCategories;
