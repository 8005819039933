import style from './ItemCost.module.css';

function ItemCost({ cost, description = 'Ціна', sum = 0, bold = false }) {
    return (
        <div className={style.priceWrapper}>
            <span>{description}: </span>
            <span className={bold ? style.itemPriceBold : style.itemPrice}>{cost}</span>
            <span className={bold ? style.itemCurrencyBold : style.itemCurrency}> ₴</span>
            {sum > 0 && (
                <>
                    <span className={style.sum}>{sum}</span>
                    <span className={style.itemCurrency}> ₴</span>
                </>
            )}
        </div>
    );
}

export default ItemCost;
